import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

import {
  SubtitleStyles,
  BackgroundStyle as BackgroundStyleEnum,
} from "../../../../../../types";

interface BackgroundStyleProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

const backgroundStyles = [
  {
    title: "None",
    value: BackgroundStyleEnum.NONE,
    content: "None",
  },
  {
    title: "Full",
    value: BackgroundStyleEnum.FULL,
    content: (
      <Box sx={{ width: "20px", height: "14px", backgroundColor: "#000000" }} />
    ),
  },
];

const getButtonSx = (isSelected: boolean) => ({
  backgroundColor: isSelected ? "#0000003b" : "transparent",
  color: isSelected ? "white" : "black",
});

export default function BackgroundStyle({
  styles,
  onUpdateStyles,
}: BackgroundStyleProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      <Typography variant="subtitle2">Background Style</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box
          sx={{
            border: "1px solid #0000003b",
            borderRadius: "12px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 1.5,
            paddingY: 0.75,
          }}
        >
          {backgroundStyles.map((bgStyle) => {
            const isSelected = styles.background_style === bgStyle.value;
            return (
              <Button
                key={bgStyle.value}
                title={bgStyle.title}
                sx={getButtonSx(isSelected)}
                onClick={() => {
                  onUpdateStyles({
                    ...styles,
                    background_style: bgStyle.value,
                    // reset outline if background has been selected
                    outline_color:
                      bgStyle.value !== BackgroundStyleEnum.NONE
                        ? undefined
                        : styles.outline_color,
                    outline_thickness:
                      bgStyle.value !== BackgroundStyleEnum.NONE
                        ? undefined
                        : styles.outline_thickness,
                  });
                }}
              >
                {bgStyle.content}
              </Button>
            );
          })}
        </Box>
        <MuiColorInput
          title="Background Color"
          format="hex"
          value={styles.background_color ?? "none"}
          onChange={(value) =>
            onUpdateStyles({ ...styles, background_color: value })
          }
          sx={{
            "& input": {
              width: 0,
              border: "none",
              paddingRight: 0,
            },
            "& fieldset": {
              display: "none",
            },
            "& button": {
              boxShadow: "none",
              borderRadius: 20,
              width: 30,
              height: 30,
              marginLeft: "8px",
            },
            "& .MuiInputBase-root": {
              padding: 0,
              margin: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            width: 54,
            height: 54,
          }}
        />
      </Box>
    </Box>
  );
}
