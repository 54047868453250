import React from "react";

import { Box, Slider, Typography } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

import { BackgroundStyle, SubtitleStyles } from "../../../../../../types";

interface TextOutlineProps {
  styles: SubtitleStyles;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function TextOutline({
  styles,
  onUpdateStyles,
}: TextOutlineProps) {
  const minOutlineThickness = 0;
  const maxOutlineThickness = 10;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
      }}
    >
      <Typography variant="subtitle2">Text Outline</Typography>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            width: "100%",
            pl: 1.5,
          }}
        >
          <Slider
            sx={{ width: "100%" }}
            value={styles.outline_thickness || 0}
            min={minOutlineThickness}
            max={maxOutlineThickness}
            step={0.1}
            onChange={(event, newValue) => {
              onUpdateStyles({
                ...styles,
                outline_thickness: newValue as number,
                // reset background style to none when outline thickness is set
                background_style:
                  newValue && newValue > 0
                    ? BackgroundStyle.NONE
                    : styles.background_style,
                background_color:
                  newValue && newValue > 0
                    ? undefined
                    : styles.background_color,
              });
            }}
            aria-labelledby="outline-thickness-slider"
          />
          <input
            type="number"
            step={0.1}
            onChange={(e) =>
              onUpdateStyles({
                ...styles,
                outline_thickness: Math.min(
                  maxOutlineThickness,
                  Math.max(minOutlineThickness, parseFloat(e.target.value)),
                ),
              })
            }
            min={minOutlineThickness}
            max={maxOutlineThickness}
            value={styles.outline_thickness || 0}
            style={{ width: "60px" }}
          />
        </Box>
        <MuiColorInput
          title="Outline Color"
          format="hex"
          value={styles.outline_color || "#000000"}
          onChange={(value) =>
            onUpdateStyles({ ...styles, outline_color: value })
          }
          sx={{
            "& input": {
              width: 0,
              border: "none",
              paddingRight: 0,
            },
            "& fieldset": {
              display: "none",
            },
            "& button": {
              boxShadow: "none",
              borderRadius: 20,
              width: 30,
              height: 30,
              marginLeft: "8px",
            },
            "& .MuiInputBase-root": {
              padding: 0,
              margin: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            width: 54,
            height: 54,
          }}
        />
      </Box>
    </Box>
  );
}
