import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import { Box } from "@mui/material";
import { Stage, Layer, Text, Rect, Group, Transformer } from "react-konva";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import ReactPlayer from "react-player";

import {
  LetterCase,
  Paragraph,
  SubtitleStyles,
  BackgroundStyle as BackgroundStyleEnum,
} from "../../types";
import { updateShortVideo } from "../../services/edit-short-video.service";

interface Props {
  videoId: string;
  url: string;
  paragraphs: Paragraph[];
  isPlaying: boolean;
  toggleIsPlaying: () => void;
  subtitleStyles: SubtitleStyles;
  onTimeUpdate: (timeInMilliseconds: number) => void;
  currentTimeInMilliseconds: number;
  onControlsReady: (controls: {
    play: () => void;
    pause: () => void;
    seekTo: (time: number) => void;
    getDuration: () => number;
  }) => void;
  onUpdateStyles: (styles: SubtitleStyles) => void;
}

export default function VideoPlayer({
  videoId,
  url,
  paragraphs,
  isPlaying,
  toggleIsPlaying,
  subtitleStyles,
  onTimeUpdate,
  currentTimeInMilliseconds,
  onControlsReady,
  onUpdateStyles,
}: Props) {
  const playerRef = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const videoDimensionsRef = useRef({ width: 640, height: 360 });
  const [stageSize, setStageSize] = useState({ width: 640, height: 360 });

  // Refs for Konva elements
  const rectRef = useRef<Konva.Rect>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const textRef = useRef<Konva.Text>(null);
  const layerRef = useRef<Konva.Layer>(null);

  // State variables for the text handle position and size
  const [textHandlePositionXPercentage, setTextHandlePositionXPercentage] =
    useState<number>(subtitleStyles?.text_handle_position_x_percentage ?? 0);
  const [textHandlePositionYPercentage, setTextHandlePositionYPercentage] =
    useState<number>(subtitleStyles?.text_handle_position_y_percentage ?? 60);
  const [textHandleWidthPercentage, setTextHandleWidthPercentage] =
    useState<number>(subtitleStyles?.text_handle_width_percentage ?? 100);

  useEffect(() => {
    if (playerRef.current) {
      onControlsReady({
        play: () => playerRef.current?.getInternalPlayer().play(),
        pause: () => playerRef.current?.getInternalPlayer().pause(),
        seekTo: (time: number) => playerRef.current?.seekTo(time, "seconds"),
        getDuration: () => playerRef.current?.getDuration() || 0,
      });
    }
  }, [onControlsReady]);

  const splitTextIntoChunks = (text: string, chunkSize: number) => {
    const words = text.split(/\s+/);
    const chunks = [];
    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, i + chunkSize).join(" "));
    }
    return chunks;
  };

  const handleProgress = useCallback(
    ({ playedSeconds }: { playedSeconds: number }) => {
      if (isPlaying) {
        const playedInMs = playedSeconds * 1000;
        onTimeUpdate(playedInMs);
      }
    },
    [onTimeUpdate, isPlaying],
  );

  useEffect(() => {
    if (playerRef.current && !isPlaying) {
      playerRef.current.seekTo(currentTimeInMilliseconds / 1000, "seconds");
    }
  }, [currentTimeInMilliseconds, isPlaying]);

  // Update handle position and size when subtitleStyles change
  useEffect(() => {
    setTextHandlePositionXPercentage(
      subtitleStyles?.text_handle_position_x_percentage ?? 0,
    );
    setTextHandlePositionYPercentage(
      subtitleStyles?.text_handle_position_y_percentage ?? 60,
    );
    setTextHandleWidthPercentage(
      subtitleStyles?.text_handle_width_percentage ?? 100,
    );
  }, [subtitleStyles]);

  const currentParagraph = useMemo(() => {
    const paragraph = paragraphs.find(
      (p) =>
        p.start <= currentTimeInMilliseconds / 1000 &&
        p.end >= currentTimeInMilliseconds / 1000,
    );

    if (paragraph) {
      const duration = (paragraph.end - paragraph.start) * 1000; // Duration in milliseconds
      const elapsedTime = currentTimeInMilliseconds - paragraph.start * 1000; // Elapsed time in milliseconds
      const chunkSize = 3; // Number of words per chunk
      const chunks = splitTextIntoChunks(paragraph.text, chunkSize);

      // Determine the current chunk based on elapsed time
      const chunkIndex = Math.floor((elapsedTime / duration) * chunks.length);
      const currentText = chunks[chunkIndex] || ""; // Fallback to empty if out of bounds

      return {
        ...paragraph,
        text: currentText, // Replace text with the current chunk
      };
    }

    return null;
  }, [paragraphs, currentTimeInMilliseconds]);

  // Get text dimensions
  const getTextDimensions = useCallback(() => {
    if (textRef.current) {
      const textNode = textRef.current;
      return {
        width: textNode.width() + 20,
        height: textNode.height(),
      };
    }
    return { width: 0, height: 200 }; // Default height
  }, []);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth - 20;
      const containerHeight = containerRef.current.offsetHeight - 20;
      const { width: videoWidth, height: videoHeight } =
        videoDimensionsRef.current;
      const aspectRatio = videoWidth / videoHeight;

      let newWidth = containerWidth;
      let newHeight = newWidth / aspectRatio;

      if (newHeight > containerHeight) {
        newHeight = containerHeight;
        newWidth = newHeight * aspectRatio;
      }

      if (newWidth !== stageSize.width || newHeight !== stageSize.height) {
        setStageSize({ width: newWidth, height: newHeight });
      }
    }
  }, [stageSize]);

  useEffect(() => {
    handleResize();
  }, [videoDimensionsRef.current.width, stageSize.width, handleResize]);

  const scale = stageSize.width / videoDimensionsRef.current.width;

  // Text styling
  const fontStyle = `${subtitleStyles.bold ? "bold" : ""} ${
    subtitleStyles.italic ? "italic" : ""
  }`.trim();
  const textDecoration = `${subtitleStyles.underline ? "underline" : ""} ${
    subtitleStyles.strikethrough ? "line-through" : ""
  }`.trim();

  // Text case handling
  let text = currentParagraph?.text || "";
  switch (subtitleStyles.letter_case) {
    case LetterCase.UPPERCASE:
      text = text.toUpperCase();
      break;
    case LetterCase.LOWERCASE:
      text = text.toLowerCase();
      break;
    case LetterCase.CAPITALIZE:
      text = text.replace(/\b\w/g, (char) => char.toUpperCase());
      break;
    default:
      break;
  }

  const handleDragEnd = useCallback(
    async (e: any) => {
      const { x, y } = e.target.position();
      const newYPercentage = (y / videoDimensionsRef.current.height) * 100;
      const newXPercentage = (x / videoDimensionsRef.current.width) * 100;
      setTextHandlePositionYPercentage(newYPercentage);
      setTextHandlePositionXPercentage(newXPercentage);

      const updatedStyles = {
        ...subtitleStyles,
        text_handle_position_y_percentage: newYPercentage,
        text_handle_position_x_percentage: newXPercentage,
      };

      onUpdateStyles(updatedStyles);
      await updateShortVideo(videoId, { subtitleStyles: updatedStyles });
    },
    [subtitleStyles, videoId, onUpdateStyles],
  );

  const handleTransform = useCallback(
    (e: any) => {
      const node = e.target;
      const scaleX = node.scaleX();
      const width = node.width();
      const group = node.getParent();

      // Calculate new width and position
      const newWidth = Math.max(50, width * scaleX);
      const originalX =
        (textHandlePositionXPercentage / 100) *
        videoDimensionsRef.current.width;

      // If transforming from left anchor
      if (node.x() !== 0) {
        // Calculate the difference in position
        const deltaX = node.x();

        // Update group position
        const newGroupX = originalX + deltaX;
        group.x(newGroupX);

        // Reset rect position within group
        node.x(0);

        // Update position percentage
        const newXPercentage =
          (newGroupX / videoDimensionsRef.current.width) * 100;
        setTextHandlePositionXPercentage(newXPercentage);
      }

      // Update width percentage
      const newWidthPercentage =
        (newWidth / videoDimensionsRef.current.width) * 100;
      setTextHandleWidthPercentage(newWidthPercentage);

      // Reset scales
      node.scaleX(1);
      node.scaleY(1);
      node.width(newWidth);

      // Update text width
      const textNode = textRef.current;
      if (textNode) {
        textNode.width(newWidth);
      }
    },
    [textHandlePositionXPercentage],
  );

  const handleTransformEnd = useCallback(async () => {
    const node = rectRef.current;
    if (!node) return;

    const newWidth = node.width();
    const newWidthPercentage =
      (newWidth / videoDimensionsRef.current.width) * 100;
    const newXPercentage =
      // @ts-ignore
      (node.getParent().x() / videoDimensionsRef.current.width) * 100;

    const updatedStyles = {
      ...subtitleStyles,
      text_handle_width_percentage: newWidthPercentage,
      text_handle_position_x_percentage: newXPercentage,
    };

    onUpdateStyles(updatedStyles);
    await updateShortVideo(videoId, { subtitleStyles: updatedStyles });
  }, [subtitleStyles, videoId, onUpdateStyles]);

  const dragBoundFunc = useCallback(
    (pos: Vector2d): Vector2d => {
      const videoWidth = videoDimensionsRef.current.width;
      const videoHeight = videoDimensionsRef.current.height;
      const textHandleWidth = (textHandleWidthPercentage / 100) * videoWidth;
      const { height: textHeight } = getTextDimensions();

      return {
        x: Math.max(0, Math.min(pos.x, videoWidth - textHandleWidth)),
        y: Math.max(0, Math.min(pos.y, videoHeight - textHeight)),
      };
    },
    [textHandleWidthPercentage, getTextDimensions],
  );

  useEffect(() => {
    if (transformerRef.current && rectRef.current) {
      transformerRef.current.nodes([rectRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, [text, textHandleWidthPercentage]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        padding: 1,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        progressInterval={50}
        onProgress={handleProgress}
        width={640}
        height={360}
        style={{
          position: "absolute",
        }}
      />

      <Stage
        width={640}
        height={360}
        scaleX={scale}
        scaleY={scale}
        onClick={toggleIsPlaying}
      >
        <Layer ref={layerRef}>
          <Group
            x={
              (textHandlePositionXPercentage / 100) *
              videoDimensionsRef.current.width
            }
            y={
              (textHandlePositionYPercentage / 100) *
              videoDimensionsRef.current.height
            }
            draggable
            onDragEnd={handleDragEnd}
            dragBoundFunc={dragBoundFunc}
            onClick={(e) => {
              e.cancelBubble = true;
            }}
          >
            <Rect
              ref={rectRef}
              x={0}
              onTransform={handleTransform}
              onTransformEnd={handleTransformEnd}
              width={
                (textHandleWidthPercentage / 100) *
                videoDimensionsRef.current.width
              }
              height={getTextDimensions().height}
              fill={
                subtitleStyles?.background_style !== BackgroundStyleEnum.NONE
                  ? subtitleStyles?.background_color || "black"
                  : "transparent"
              }
              opacity={0.8}
              cornerRadius={
                subtitleStyles?.background_style === BackgroundStyleEnum.ROUNDED
                  ? 10
                  : 0
              }
              listening
            />
            <Text
              ref={textRef}
              x={0}
              text={text}
              width={
                (textHandleWidthPercentage / 100) *
                videoDimensionsRef.current.width
              }
              fontSize={subtitleStyles?.size || 24}
              fill={subtitleStyles?.color || "white"}
              fontFamily={subtitleStyles?.font}
              fontStyle={fontStyle}
              textDecoration={textDecoration}
              lineHeight={subtitleStyles?.line_height}
              letterSpacing={subtitleStyles?.letter_spacing}
              align={subtitleStyles?.text_align || "center"}
              verticalAlign="middle"
              stroke={subtitleStyles?.outline_color}
              strokeWidth={subtitleStyles?.outline_thickness}
              wrap="word"
              padding={20}
              listening
            />
          </Group>
          <Transformer
            ref={transformerRef}
            rotateEnabled={false}
            enabledAnchors={["middle-left", "middle-right"]}
            boundBoxFunc={(oldBox, newBox) =>
              newBox.width < 50 ? oldBox : newBox
            }
            anchorSize={8}
            anchorStroke="blue"
            anchorFill="white"
            anchorCornerRadius={4}
          />
        </Layer>
      </Stage>
    </Box>
  );
}
